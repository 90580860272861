<template>
  <div>
    <rxNavBar title="实名认证"></rxNavBar>
    <div>
      <!--            业主信息-->
      <div class="part">
        <div class="part-inputpart">
          <div class="part-inputpart-row">
            <div :class=" 0==userName.trim().length ? 'content-none' : 'content-have'"></div>
            <span class="part-inputpart-row-header">真实姓名</span>
            <span class="content-divide">|</span>
            <input v-model="userName" placeholder="请输入姓名" @blur="checkName">
          </div>
          <div class="part-inputpart-row">
            <span :class=" 0==idCard.trim().length ? 'content-none' : 'content-have' "></span>
            <span class="part-inputpart-row-header"
                  @click="showPicker = true">{{ certificateType.dictionaryTitle || '身份证' }}</span>
            <img style="margin-left: 5px"
                 :class=" showPicker ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                 src="../../../assets/images/triangle.png">
            <span class="content-divide">|</span>
            <input oninput="value= value.replace(/[^\d|xX]/g,'')" maxlength="18" v-model="idCard"
                   :placeholder="certificateType.dictionaryTitle ? '填写'+certificateType.dictionaryTitle : '填写身份证'" @blur="animateProxyIdCard">
          </div>
          <div id="occupationPanel" class="part-inputpart-row" @click="isOccupationShow = true">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">职业</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext"
                  :class="occupation=='非必填' ? '' : 'part-inputpart-row-normaltext' ">{{ occupation.dictionaryTitle == undefined?occupation:occupation.dictionaryTitle }}</span>
            <img class="part-inputpart-row-right"
                 :class=" isOccupationShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                 src="../../../assets/images/triangle.png">
          </div>
          <div id="constellationPanel" class="part-inputpart-row" @click="isConstellationShow = true">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">星座</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext"
                  :class="constellation=='非必填' ? '' : 'part-inputpart-row-normaltext' ">{{ constellation.dictionaryTitle == undefined?constellation:constellation.dictionaryTitle }}</span>
            <img class="part-inputpart-row-right"
                 :class=" isConstellationShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                 src="../../../assets/images/triangle.png">
          </div>
          <div id="hobbyPanel" class="part-inputpart-row" @click="isHobbyShow = true">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">爱好</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext"
                  :class="hobby=='非必填' ? '' : 'part-inputpart-row-normaltext' ">{{ hobby.dictionaryTitle == undefined?hobby:hobby.dictionaryTitle }}</span>
            <img class="part-inputpart-row-right"
                 :class=" isHobbyShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                 src="../../../assets/images/triangle.png">
          </div>
        </div>
      </div>
      <div @click="saveData" class="part part-button"
           :class="0==userName.trim().length || 0==idCard.trim().length  ? '' : 'part-button-enabled' ">下一步
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          show-toolbar
          :columns="certificateTypeArr"
          @cancel="showPicker = false"
          :default-index="defaultIndex"
          value-key="dictionaryTitle"
          @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="isOccupationShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="occupationArr"
          @cancel="cancelPicker(1)"
          :default-index="occupationIndex"
          value-key="dictionaryTitle"
          @confirm="selectOccupationOption"
      />
    </van-popup>
    <van-popup v-model="isConstellationShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="constellationArr"
          @cancel="cancelPicker(2)"
          :default-index="constellationIndex"
          value-key="dictionaryTitle"
          @confirm="selectConstellationOption"
      />
    </van-popup>
    <van-popup v-model="isHobbyShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="hobbyArr"
          @cancel="cancelPicker(3)"
          :default-index="hobbyIndex"
          value-key="dictionaryTitle"
          @confirm="selectHobbyOption"
      />
    </van-popup>
  </div>
</template>

<script>

import {
  NavBar, Picker, Popup, Toast
} from 'vant'
import {nameCertification, queryBaseData, queryNameCertification, queryPersonDetails} from "../../../getData/getData";
import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";


export default {

  name: "RealNameAuthentication",

  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    rxNavBar
  },

  data() {
    return {

      showPicker: false,
      defaultIndex: '',
      certificateType: '',
      user_id: '',
      //职业
      isOccupationShow: false,
      // 星座
      isConstellationShow: false,
      // 爱好
      isHobbyShow: false,
      userName: '',
      idCard: '',
      occupation: '非必填',
      constellation: '非必填',
      hobby: '非必填',
      occupationIndex: '',
      constellationIndex: '',
      hobbyIndex: '',
      occupationArr: [],
      constellationArr: [],
      hobbyArr: [],
      certificateTypeArr: []
    }
  },

  created() {
    this.initDicData()
  },

  methods: {
    cancelPicker(type){
      //职业
      if(type == 1){
        this.occupation = '非必填'
        this.occupationIndex = -1
      }
      //星座
      if(type == 2){
        this.constellation = '非必填'
        this.constellationIndex = -1
      }
      //爱好
      if(type == 3){
        this.hobby = '非必填'
        this.hobbyIndex = -1
      }
      this.isOccupationShow = false
      this.isConstellationShow = false
      this.isHobbyShow = false
    },
    onConfirm(value, index) {
      if(value){
        this.certificateType = value
        this.defaultIndex = index
      }
      this.showPicker = false
      this.animateProxyIdCard()
    },

    checkName() {
      var re = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
      if (this.userName) {
        if (!re.test(this.userName)) {
          Toast('请输入正确的姓名')
          this.userName = '';
        }
      }
    },

    animateProxyIdCard() {
      if ('0' == this.certificateType.dictionaryValue) {
        var re = /(^\d{15}$)|bai(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (this.idCard) {
          if (!re.test(this.idCard)) {
            Toast('身份证格式不正确')
            this.idCard = '';
          }
        }
      }
    },

    //初始化字典数据
    initDicData: function () {
      const that = this
      let initData = {}
      initData.dbName = ["occupation", "constellation", "hobby"]
      initData.fdName = ["CERTIFICATETYPEMAP"]
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.occupationArr = response.data.data.occupation
          that.constellationArr = response.data.data.constellation
          that.hobbyArr = response.data.data.hobby
          that.certificateTypeArr = response.data.data.CERTIFICATETYPEMAP
          that.initData()
        })
      })
    },
    //初始化数据
    initData() {
      let that = this
      let queryPersonDetailsData={}
      queryPersonDetailsData.user_id= globaluserId()
      queryPersonDetails(queryPersonDetailsData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          let data = response.data.data
          if('是'===data.nameAuthentication){
            that.idCard = data.certificateCode || ''
            that.userName = data.userName ||''
            that.certificateType = that.certificateTypeArr[Number(data.certificateType)] || that.certificateTypeArr[0]
          }else {
            that.certificateType = that.certificateTypeArr[0]
          }
          if(data.constellation_id)
            for(let i in that.constellationArr)
              if(data.constellation_id == that.constellationArr[i].id){
                that.constellation = that.constellationArr[i]
                that.constellationIndex = i
              }

          if(data.occupation_id)
            for(let i in that.occupationArr)
              if(data.occupation_id == that.occupationArr[i].id){
                that.occupation = that.occupationArr[i]
                that.occupationIndex = i
              }
          if(data.hobby)
            for(let i in that.hobbyArr)
              if(data.hobby == that.hobbyArr[i].id){
                that.hobby = that.hobbyArr[i]
                that.hobbyIndex = i
              }
        })
      })
    },
    //保存数据
    saveData() {
      const that = this
      let initData = {}
      initData.addStaffId=getStaffId()
      initData.user_id = globaluserId()
      if (0 == that.userName.trim().length || 0 == that.idCard.trim().length || !that.certificateType) return;
      initData.userName = that.userName
      initData.idCard = that.idCard
      initData.certificateType = that.certificateType.dictionaryValue
      initData.gender = 2
      if ('0' == that.certificateType.dictionaryValue)
        initData.gender = that.idCard.substring(16, 17) % 2
      if (that.occupation.id)
        initData.occupation_id = that.occupation.id
      if (that.constellation.id)
        initData.constellation_id = that.constellation.id
      if (that.hobby.id)
        initData.hobby = that.hobby.id
      console.log(initData)
      nameCertification(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          if (200 == response.status) {
            that.$router.push('UploadIDCard')
          } else {
            Toast('认证失败')
          }
        })
      })
    },

    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    //点击下拉框选项 进行数据绑定
    selectOccupationOption(value,index) {
      if(value){
        this.occupation = value
        this.occupationIndex = index
      }
      this.isOccupationShow = false

    },
    selectConstellationOption(value,index) {
      if(value){
        this.constellation = value
        this.constellationIndex = index
      }
      this.isConstellationShow = false
    },
    selectHobbyOption(value,index) {
      if(value){
        this.hobby = value
        this.hobbyIndex = index
      }
      this.isHobbyShow = false
    }

  }


}
</script>

<style scoped lang="less">

.part {
  margin: 15px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.part-button {
  height: 45px;
  border-radius: 10px;
  background-color: #ededed;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  font-weight: 900;
  margin-top: 100px;
}

.part-button-enabled {
  background-image: linear-gradient(to right, #ffc274, #ff5d3b);
}

.part-inputpart div:last-child {
  border: none;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;

  .arrows {
    height: 0;
    width: 0;
    border: 3.5px solid;
    border-color: black transparent transparent transparent;
    margin-left: 10px;
  }
}

.part-inputpart-row-header {
  font-weight: 900;
}

.part-inputpart-row input {
  border: 0;
  //width: 100%;
  flex: auto;
}

.part-inputpart-row input::-webkit-input-placeholder {
  color: #d8d8d8;
}

.part-inputpart-dropDownList {
  height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 92.5%;
  position: absolute;
  z-index: 99;
}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-enablenon {
  margin-left: 26px;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  //width: 100%;
  flex: auto;
}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-housetype input {
  width: 18px;
  margin-left: 10px;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}


</style>
